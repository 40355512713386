import { makeObservable, observable, runInAction } from 'mobx';
import { uniqBy } from 'lodash';
import { BaseStore, RequestConfig } from '../util/baseStore';
import { IPaginated } from '../vehicles/vehicles';
import {
  IVehicle,
  PaginatedVehiclesSchema,
  VehicleSchema,
} from '../vehicles/validation';
import { IQuery } from '../users/users';
import { IUser, PaginatedUsersSchema, UserSchema } from '../users/validation';
import {
  IRenewal,
  PaginatedRenewalSchema,
  RenewalSchema,
} from '../renewals/validation';

class AdminStore extends BaseStore {
  constructor(config: RequestConfig) {
    super(config);
    makeObservable(this, {
      vehicles: observable,
      users: observable,
      renewals: observable,
    });
  }

  // State
  loading = false;
  vehicles: IPaginated<IVehicle> = {
    total: 0,
    results: [],
    skip: 0,
    take: 0,
  };
  users: IPaginated<IUser> = {
    total: 0,
    results: [],
    skip: 0,
    take: 0,
  };
  renewals: IPaginated<IRenewal> = {
    total: 0,
    results: [],
    skip: 0,
    take: 0,
  };

  async getUsers(query: IQuery) {
    this.setLoading(true);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'admin/users',
      validation: PaginatedUsersSchema,
      params: query,
    });

    this.setLoading(false);

    if (!result.success) {
      return result;
    }

    runInAction(() => {
      this.users = {
        total: result.data.count,
        results: uniqBy(
          [...this.users.results, ...result.data.entries],
          (v) => v.id,
        ),
        skip: query.skip ?? 0,
        take: query.take ?? 10,
      };
    });

    return result;
  }

  async getRenewals(query: IQuery, activeOnly = false) {
    this.setLoading(true);

    console.log(`searching activeonly ${activeOnly}`);

    const result = await this.sendRequest({
      method: 'GET',
      path: 'admin/renewals',
      validation: PaginatedRenewalSchema,
      params: { ...query, active: activeOnly },
    });

    this.setLoading(false);

    if (!result.success) {
      return result;
    }

    runInAction(() => {
      this.renewals = {
        total: result.data.count,
        results: uniqBy(
          [...this.renewals.results, ...result.data.entries],
          (v) => v.id,
        ),
        skip: query.skip ?? 0,
        take: query.take ?? 10,
      };
    });

    return result;
  }

  async getRenewal(options: { id?: number; vehicleId?: number }) {
    return this.withState(
      this.sendRequest({
        method: 'GET',
        path: 'admin/renewal',
        validation: RenewalSchema,
        params: options,
      }),
    );
  }

  async getVehicles(query: IQuery) {
    this.setLoading(true);
    const result = await this.sendRequest({
      method: 'GET',
      path: 'admin/vehicles',
      params: query,
      validation: PaginatedVehiclesSchema,
    });

    this.setLoading(false);

    if (!result.success) {
      return result;
    }

    runInAction(() => {
      this.vehicles = {
        total: result.data.count,
        results: uniqBy(
          [...this.vehicles.results, ...result.data.entries],
          (v) => v.id,
        ),
        skip: query.skip ?? 0,
        take: query.take ?? 10,
      };
    });

    return result;
  }

  async getVehicle(id: number) {
    return this.withState(
      this.sendRequest({
        method: 'GET',
        path: 'admin/vehicle',
        validation: VehicleSchema,
        params: { id },
      }),
    );
  }

  async addUser(input: AddUserInput) {
    return this.withState(
      this.sendRequest({
        method: 'POST',
        path: 'admin/user',
        validation: UserSchema,
        body: input,
      }),
    );
  }
}

export interface AddUserInput {
  email: string;
  firstName: string;
  lastName: string;
  contactNumber: string;
  permissionIds: string[];
  companyId: number;
  testUser: boolean;
}

export default AdminStore;
