import React, { useState } from 'react';
import { utc } from 'moment';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { toast } from 'react-toastify';
import ReactGA from 'react-ga'; // Google Analytics
// @material-ui
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Grid,
  Typography,
  Paper,
  Button,
  StepLabel,
  Step,
  Stepper,
  TextField,
} from '@material-ui/core';
// @icons
import { ArrowBackIos, ArrowForwardIos } from '@material-ui/icons';
// @logic
import { useStore } from 'logic/store';
// import { TDeliveryEstimate } from 'logic/store/stores/delivery.store'; // comment out for now
// @components
import RenewalInvoice from 'components/InvoiceTemplate';
import Currency from 'utilities/handleCurrency';
import PaymentModal from 'components/utilities/Payment';
import GooglePlaces, { PlacesResult } from 'components/utilities/Google/Places';
import MapIframe from 'components/utilities/Google/Map/Iframe';
// @local
import { IUser } from 'logic/stores/users/validation';
import { LicenceRenewalQuote } from 'logic/stores/renewals/validation';
import { IVehicle } from 'logic/stores/vehicles/validation';
import useStyles from './styles';
import { NotAcceptingRenewalsDialog } from './NotAcceptingRenewals';

export enum Steps {
  DeliveryAddressConfirmation,
  LicenceRenewalQuoteConfimation,
  Payment,
  FinalConfirmation,
}

const getSteps = () => {
  return ['Collection', 'Renewal quote', 'Payment', 'Done'];
};

type IPayload = Pick<IUser, 'deliveryAddress' | 'deliveryAddressAdditional'>;

export interface IRenewalResult {
  result: string;
  reason: string;
}

interface IStartRenewalProps {
  step: Steps;
  result?: IRenewalResult;
}

const NoStartRenewal = observer<IStartRenewalProps>((props) => {
  const history = useHistory();

  return (
    <Grid container alignContent="center">
      <Grid item md={12} xs={12}>
        <NotAcceptingRenewalsDialog
          open
          onClose={() => history.push('/manage-vehicles')}
        />
      </Grid>
    </Grid>
  );
});

export const StartRenewal = observer<IStartRenewalProps>((props) => {
  const { result } = props;

  const errorPage = !result || !result.result || result.result !== 'successful';

  const match = useRouteMatch('/start-renewal/*/:vehicleId')!;
  const { vehicleId } = match.params as { vehicleId: number };

  const classes = useStyles();
  const store = useStore();

  const [vehicle, setVehicle] = useState<IVehicle>();

  const { userId, user } = store.auth;

  let stepCount = 0;
  const history = useHistory();
  // Default to sorted address
  const defaultCoords = [-26.0406575, 28.0596701] as [number, number];
  const now = utc();

  // Set states
  const [title, setTitle] = React.useState('');
  const [address, setAddress] = React.useState('');
  const [additionalAddress, setAdditionalAddress] = React.useState<
    string | null
  >(user?.deliveryAddressAdditional || null);
  const [, setAddressCoords] = React.useState(defaultCoords); // comment out addressCoords for now
  // const [, setDeliveryQuote] = React.useState({} as TDeliveryEstimate)  // comment delivery for now
  const [licenceRenewalQuote, setLicenceRenewalQuote] = React.useState(
    {} as LicenceRenewalQuote,
  );
  const [paymentReference] = React.useState(
    `renewal_vehicle::${vehicleId}_${now.format('YYYYMMDD')}`,
  );

  const [serviceFeeValue, setServiceFeeValue] = React.useState<number | null>(
    null,
  );
  const { managedVariables } = store.managedVariables;

  const getSelectedLocation = (location: PlacesResult) => {
    setTitle(location.name);
    if (location.formatted_address) {
      setAddress(location.formatted_address);
    }
    setAddressCoords(location.coordinates);
    // TODO: decode location results for billing address
  };

  const steps = getSteps();

  const updateAddress = async (update: IPayload) => {
    if (!userId) {
      return;
    }
    const result = await store.users.update(userId, update as Partial<IUser>);
    if (result) {
      toast.success(
        `Your delivery address has been updated. To proceed click next`,
      );
    }
  };

  React.useEffect(() => {
    async function loadVehicle(id: number) {
      const result = await store.vehicles.loadVehicle(id);

      if (result.success && result.data.count === 1) {
        setVehicle(result.data.entries[0]);
      } else {
        console.log(`Unable to load vehicle`);
      }
    }

    if (vehicleId != null) {
      loadVehicle(vehicleId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (user != null) {
      if (user.deliveryCoordinates) {
        setAddressCoords(user.deliveryCoordinates);
      }
      if (user.deliveryAddress) {
        setAddress(user.deliveryAddress);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(user)]);

  React.useEffect(() => {
    if (props.step === Steps.LicenceRenewalQuoteConfimation && user) {
      // update delivery address if new address is entered
      const addressChanged = user.deliveryAddress !== address && address !== '';
      const additionalAddressChanged =
        user.deliveryAddressAdditional !== additionalAddress &&
        additionalAddress !== '';
      if (addressChanged || additionalAddressChanged) {
        updateAddress({
          deliveryAddress: address,
          deliveryAddressAdditional: additionalAddress!,
        });
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [address, additionalAddress, props.step, JSON.stringify(user)]);

  React.useEffect(() => {
    if (vehicle != null) {
      store.licenseFees
        .getLicenceRenewalQuote({
          region: vehicle.registrationRegion,
          vehicleType: vehicle.vehicleType,
          vehicleWeight: vehicle.weightKg,
          licenseExpiryDate: vehicle.licenseExpires,
        })
        .then((result) => {
          if (result.success) {
            setLicenceRenewalQuote(result.data);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(vehicle)]);

  const paymentSuccess = async () => {
    console.log('paymentSuccess is deprecated');
  };

  React.useEffect(() => {
    const fetchData = async () => {
      let serviceFee = store.managedVariables.byName('serviceFee');

      if (!serviceFee) {
        await store.managedVariables.get();
        serviceFee = store.managedVariables.byName('serviceFee');
      }

      setServiceFeeValue(serviceFee ? serviceFee.value : null);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    if (props.step === Steps.FinalConfirmation) {
      // Record the conversion
      ReactGA.event({
        category: 'conversion',
        action: 'purchase',
        dimension1: userId ? `${userId}` : undefined,
        dimension2: paymentReference,
      });
      // Record the ecommerce
      ReactGA.plugin.require('ecommerce');
      ReactGA.plugin.execute('ecommerce', 'addTransaction', {
        id: paymentReference,
        revenue: invoiceTotal,
      });
      ReactGA.plugin.execute('ecommerce', 'send', null);
      ReactGA.plugin.execute('ecommerce', 'clear', null);
    }
  }, [props.step]);

  React.useEffect(() => {
    if (props.step === Steps.DeliveryAddressConfirmation) {
      stepCount = 0;
    } else if (props.step === Steps.LicenceRenewalQuoteConfimation) {
      stepCount = 1;
    } else if (props.step === Steps.Payment) {
      stepCount = 2;
    } else if (props.step === Steps.FinalConfirmation) {
      stepCount = 3;
    }
  }, [props.step]);

  const invoiceServiceCharge = serviceFeeValue;
  const invoiceTotal =
    invoiceServiceCharge && licenceRenewalQuote.total
      ? Currency({ amount: licenceRenewalQuote.total }).add(
          Currency({ amount: invoiceServiceCharge }),
        )
      : null;

  const getStepContent = (step: Steps) => {
    switch (step) {
      case Steps.DeliveryAddressConfirmation:
        return (
          <div>
            <Typography>
              Where would you like to receive your new licence disc?
            </Typography>
            {/* Delivery or collection options */}
            {address !== '' ? (
              <TextField
                fullWidth
                color="secondary"
                margin="normal"
                variant="outlined"
                label="Complex / Building (Optional)"
                placeholder="Complex or Building Name, unit number or floor"
                value={additionalAddress}
                onChange={(e) => setAdditionalAddress(e.target.value)}
              />
            ) : null}
            <GooglePlaces
              getSelectedLocation={getSelectedLocation}
              textfieldProps={{
                placeholder: address,
                variant: 'outlined',
                label: 'Search for Location',
                margin: 'normal',
                fullWidth: true,
              }}
            />
            {address !== '' ? (
              <MapIframe title={title} address={address} />
            ) : null}
          </div>
        );
      case Steps.LicenceRenewalQuoteConfimation:
        return <RenewalInvoice licenceRenewalQuote={licenceRenewalQuote} />;
      case Steps.Payment:
        return (
          <div>
            <Alert severity="info">
              <span>
                You will need to make a payment of{' '}
                <b>{invoiceTotal ? invoiceTotal.toFormat('$0,0.00') : null}</b>
              </span>
            </Alert>
            {invoiceTotal && user ? (
              <PaymentModal
                amountInCents={invoiceTotal.toObject().amount}
                reference={paymentReference}
                user={user}
                vehicleId={vehicleId}
                noDialog
                description="undefined" // TODO: Change this to something useful, it was previously 'undefined' and is not used
                successCallback={paymentSuccess}
              />
            ) : null}
          </div>
        );
      case Steps.FinalConfirmation:
        // Actual result dictates what's shown...
        if (errorPage) {
          return (
            <div>
              <Alert severity="error">
                <AlertTitle>
                  <b>There was an issue with capturing your payment.</b>
                </AlertTitle>
                Please send an email with your details to{' '}
                <a href={`mailto: ${store.companies.company.contactEmail}`}>
                  {store.companies.company.contactEmail}
                </a>{' '}
                to arrange alternative payment.
              </Alert>
            </div>
          );
        }

        return (
          <div>
            <Alert severity="success">
              <AlertTitle>
                <b>
                  Payment has been successfully processed. Please check your
                  email for the payment confirmation.
                </b>
              </AlertTitle>
              Your licence disc renewal has been activated.
              <br />
              We&apos;ll let you know as soon as it&apos;s ready!
            </Alert>
            <br />
            <Alert severity="info">
              <AlertTitle>
                <b>Please Note</b>
              </AlertTitle>
              Sometimes the department blocks the printing of your disc. This
              can happen if:
              <br />
              - There are outstanding enforcement orders / warrants (fines)
              <br />
              - You have other expired vehicles
              <br />
              - Your vehicle isn&apos;t roadworthy
              <br />
              <br />
              Should this happen, we&apos;ll be in touch.
            </Alert>
          </div>
        );
      default:
        return <Typography>Unknown step</Typography>;
    }
  };

  const handleNext = React.useCallback(() => {
    if (props.step === Steps.DeliveryAddressConfirmation) {
      history.push(`/start-renewal/renewal-quote/${vehicleId}`);
    } else if (props.step === Steps.LicenceRenewalQuoteConfimation) {
      history.push(`/start-renewal/payment/${vehicleId}`);
    } else if (props.step === Steps.Payment) {
      history.push(`/start-renewal/result/${vehicleId}`);
    }
  }, [props.step, vehicleId]);

  const handleBack = () => {
    try {
      if (errorPage) {
        handleReset();
        return;
      }

      history.back();
      return;
    } catch (e) {
      // Can't go back in history
      console.log('No history');
      history.replace(`/manage-vehicles`);
    }

    if (props.step === Steps.LicenceRenewalQuoteConfimation) {
      history.push(`/start-renewal/collection/${vehicleId}`);
    } else if (props.step === Steps.Payment) {
      history.push(`/start-renewal/renewal-quote/${vehicleId}`);
    }
  };

  const handleReset = () => {
    history.push(`/start-renewal/collection/${vehicleId}`);
  };

  const handleDone = () => {
    history.push('/manage-vehicles');
  };

  return (
    <Grid container alignContent="center">
      <Grid item md={12} xs={12}>
        <Typography
          variant="h4"
          align="center"
          className={classes.headingSpace}
        >
          Start Renewal
        </Typography>
        <Stepper activeStep={stepCount} orientation="horizontal">
          {steps.map((label, index) => (
            <Step key={label}>
              <StepLabel>{label}</StepLabel>
            </Step>
          ))}
        </Stepper>

        <div className={classes.startRenewalDiv}>
          <div className={classes.actionsContainer}>
            <div className={classes.actions}>
              <Button
                variant="outlined"
                disabled={props.step === Steps.FinalConfirmation}
                onClick={handleBack}
                className={classes.button}
                startIcon={<ArrowBackIos />}
              >
                Back
              </Button>
              {props.step !== Steps.FinalConfirmation ? (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNext}
                  className={classes.button}
                  endIcon={<ArrowForwardIos />}
                  disabled={
                    props.step === Steps.Payment ||
                    address === '' ||
                    address == null
                  }
                >
                  Next
                </Button>
              ) : (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleDone}
                  className={classes.button}
                >
                  Finish
                </Button>
              )}
            </div>
          </div>
          {getStepContent(props.step)}
        </div>

        {stepCount === steps.length && (
          <Paper square elevation={0} className={classes.resetContainer}>
            <Typography>All steps completed - you&apos;re finished</Typography>
            <Button onClick={handleReset} className={classes.button}>
              Reset
            </Button>
            <Button onClick={handleDone} className={classes.button}>
              Done
            </Button>
          </Paper>
        )}
      </Grid>
    </Grid>
  );
});

const Wrapper: React.FC<IStartRenewalProps> = (props) => {
  const store = useStore();

  const { user } = store.auth;

  const allowed = [
    'daniela@sorted.co.za',
    'daniela.f.morris@gmail.com',
    'mrmarkchaplin@gmail.com',
    'mark@sorted.co.za',
  ];

  if (user?.email && allowed.includes(user.email.toLowerCase())) {
    return <StartRenewal {...props} />;
  }

  return <NoStartRenewal {...props} />;
};

export default Wrapper;
